<template>
  <div>
    <div class='row'>
      <div class='col-lg-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header d-flex justify-content-between align-items-center'>
            <vb-headers-card-header :data="{ title: 'Ders Paketleri' }" />
            <router-link to='/paket-dersi-ekle' type='button' class='btn btn-primary btn-with-addon text-nowrap'>
              <span class='btn-addon'>
                <i class='btn-addon-icon fe fe-plus-circle'></i>
              </span>
              Yeni Paket Ekle
            </router-link>
          </div>
          <div class='card-body'>
            <!-- TABLO -->
            <div class='table-responsive text-nowrap'>
              <a-table
                :locale='{emptyText: "Paket Bulunamadı"}'
                :pagination='false'
                :loading='loading'
                :columns='columns'
                :data-source='packageCourseItems'
                :row-key='(record) => record.id'
              >
                <template #courseInfo='{record}'>
                  <div>{{ record.id }}</div>
                </template>
                <template #coverphoto='{ record }'>
                  <div class='vb__utils__avatar'>
                    <a-image
                      :src='record.coverPhoto.coverImage2X'
                      alt='User'
                      fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                    />
                  </div>
                </template>
                <template #courseName='{ record }'>
                  <div>{{ record.name }}</div>
                </template>
                <template #personalTrainer='{ record }'>
                  <div class='vb__utils__avatar'>
                    <a-image
                      :src='record.personalTrainer.avatar?.avatar4X ?? " "'
                      alt='avatar'
                      fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                    />
                  </div>
                  <div class='ml-2'>{{ record.personalTrainer.fullName }}</div>
                </template>
                <template #description='{ record }'>
                  <div style='max-width: 150px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;'>{{ record.description }}</div>
                </template>
                <template #price='{ record }'>
                  <div>{{ record.price === 0 ? 'ÜCRETSİZ' : record.price + ' ₺' }}</div>
                </template>
                <template #promotionalVimeoEmbedUrl='{ record }'>
                  <div><a :href='record.promotionalVimeoEmbedUrl' target='_blank'>{{ record.promotionalVimeoEmbedUrl }}</a></div>
                </template>
                <template #pcOrder='{ record }'>
                  <div :class='record.pcOrder !== null ? "" : "font-italic text-gray-3"'>{{ record.pcOrder ?? "Belirtilmemiş" }}</div>
                </template>
                <template #operation='{ record }'>
                  <div>
                    <router-link :to='`/ders-paket-detaylari/${record.id}`'>
                      <a-button class='btn btn-primary mr-1'>
                        <i class='fe fe-settings text-white' />
                      </a-button>
                    </router-link>

                    <a-popconfirm
                      title='Ders Paketini Silmek İstediğinize Emin Misiniz?'
                      ok-text='Evet'
                      cancel-text='Hayır'
                      @confirm='confirmDelete(record.id)'
                    >
                      <a-button class='btn btn-danger'>
                        <i class='fe fe-trash text white' />
                      </a-button>
                    </a-popconfirm>
                  </div>
                </template>
              </a-table>
            </div>
          </div>
          <div class='card-body'>
            <!-- PAGINATION -->
            <div class='clearfix'>
              <a-pagination
                class='float-right'
                @change='loadPackageCourse'
                v-model:page-size='pagination.recordsPerPage'
                :default-page-size='pagination.recordsPerPage'
                v-model:current='pagination.currentPage'
                :default-current='pagination.currentPage'
                :total='pagination.totalRecords'
                :show-total='total => `Toplam ${total} kayıt`'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PackageCourseAdministration } from '../../services/api-service'

export default {
  name: 'VbDersPaketleri',
  data: function() {

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title:'Fotoğraf',
        dataIndex: 'coverPhoto',
        class: 'w-auto',
        slots: { customRender: 'coverphoto' },
      },
      {
        title: 'Paket Adı',
        dataIndex: 'name',
        class: 'width-50',
      },
      {
        title: 'Kişisel Antrenör',
        dataIndex: 'personalTrainer',
        class: 'd-flex align-items-center',
        slots: { customRender: 'personalTrainer' },
      },
      {
        title: 'Açıklaması',
        dataIndex: 'description',
        slots: { customRender: 'description' },
      },
      {
        title: 'Ders Paket Fiyatı',
        dataIndex: 'price',
        slots: { customRender: 'price' },
      },
      {
        title: 'Sıralaması',
        dataIndex: 'pcOrder',
        slots: { customRender: 'pcOrder' },
      },
      {
        title: 'Ders Kısa Videosu ',
        dataIndex: 'promotionalVimeoEmbedUrl',
        class: 'w-100',
        slots: { customRender: 'promotionalVimeoEmbedUrl' },
      },
      {
        title: 'Yönet',
        class: 'text-center',
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
      },
    ]

    return {
      columns,
      loading: true,
      packageCourseItems: [],
      pagination: { totalPages: 1, currentPage: 1, totalRecords: 0, recordsPerPage: 1 },
    }
  },
  mounted() {
    this.loadPackageCourse()
  },
  methods: {
    async loadPackageCourse(page) {
      this.loading = true

      try {
        const { data } = await PackageCourseAdministration.listPackageCoursesForAdministration(page ?? 1)

        if (data) {
          this.packageCourseItems = data.packageCourses
          this.pagination = data.pagination
        }

      } catch (e) {
        console.log(e)
      }

      this.loading = false
    },
    async confirmDelete(id) {
      this.loading = true

      try {
        await PackageCourseAdministration.deletePackageCourse({ id })
        this.packageCourseItems = this.packageCourseItems.filter(x => x.id !== id)

      } catch (e) {
        console.log(e)
      }

      this.loading = false
    },
  },
}
</script>
